<template>
    <v-row>

 

        <v-col cols="12" lg="8" xl="8">
            <div class="normal-card bg-contcolor">
                <div class="detail-item">
                    <div class="leaflet-area pos-relative">
                        <div id="leafletmap"></div>
                    </div>
                </div>
            </div>
            <div class="normal-card highchart-width-wrapper bg-contcolor">
                <div class="hint-txt">資料量大，請耐心等候</div>
                <div class="results-area mt-5">
                    <div class="loading-area">
                        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                    <div id="hichart-container" class="hichart-container"></div>
                </div>
            </div>
            <div class="normal-card wind-width-wrapper bg-contcolor">
                <div class="hint-txt">資料量大，請耐心等候</div>
                <div class="loading-area">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="echartbja-chart echartWindRose-chart" id="echartWindRose-chart"></div>

                <div id="chart" style="width: 600px;height:600px;"></div>

            </div>
        </v-col>
        <v-col cols="12" lg="4" xl="4">
            <div class="normal-card bg-contcolor">
                <h2 class="m-title">
                    <div class="txt">
                        <span class="me-2">感測數值</span>
                        <span class="txt-yellow subtxt">[{{station_id}}]</span>
                    </div> 
                    <div class="btn-area">
                        <v-btn color="primary">事件簿</v-btn>
                    </div>
                </h2>
                <v-row class="rt-list">
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item pmtwo_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">PM<sub>2.5</sub></div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item pmten_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">PM10</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item co_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">CO</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item co2_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">CO2</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item tvoc_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">TVOC</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item no2_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">NO2</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item o3_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">O3</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item temp_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">溫度</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item humi_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">濕度</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item nh3_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">NH3</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="item-wrapper">
                        <div class="item h2s_item">
                            <div class="mlt">
                                <div class="dot"></div>
                                <div class="title">H2S</div>
                            </div>
                            <div class="mlt">
                                <div class="cont"></div>
                            </div>
                        </div>
                    </v-col>
                    

                </v-row>  
            </div><!-- /.normal-card -->
            <!--<v-card class="normal-card no-pa">
                <h2 class="m-title pa-md-6 pa-2 mb-0">
                    <div class="txt">
                        可疑污染源
                        <div class="ala-txt">
                            鄰近測站為龍井，當前風向為44°，風速為5.7
                        </div> 
                    </div> 
                </h2>

                <v-simple-table class="scroll-auto">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                名稱
                            </th>
                            <th class="text-center">
                                類型
                            </th>
                            <th class="text-center">
                                距離
                            </th>
                            <th class="text-center">
                                上風處
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                        <tr>
                            <td>正佳珍食品股份有限公司</td>
                            <td class="text-center">
                                鍋爐蒸氣產生程序
                            </td>
                            <td class="text-center">
                                2.09
                            </td>
                            <td class="text-center">
                                是
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>

            </v-card>--><!-- /.normal-card -->
            <div class="normal-card bg-contcolor">
                <h2 class="m-title">
                    <div class="txt">
                        可疑污染源測站資料
                    </div>
                </h2>


                <v-simple-table class="scroll-auto">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-center"><div class="w-1">污染類型</div></th>
                            <th class="text-center"><div class="w-1">污染最大值</div></th>
                            <th class="text-center"><div class="w-1">開始時間</div></th>
                            <th class="text-center"><div class="w-1">處理人</div></th>
                            <th class="text-center"><div class="w-1">處理狀況</div></th>
                            <th class="text-center"><div class="w-1">結束時間</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in events.evelist" :key="index" @click="fetchRecords(item)">
                            <td class="text-center">{{item.device}}</td>
                            <td class="text-center">{{item.kind}}</td>
                            <td class="text-center">{{item.maxval}}</td>
                            <td class="text-center">{{item.stime}}</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center">{{item.etime}}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div><!-- /.normal-card -->
            
        </v-col>
    </v-row>  
</template>
<script>

import locationIcon from '@/assets/images/all/icon_location.svg';
import locationIcon_red from '@/assets/images/all/icon_location_red.svg';
import locationIcon_yellow from '@/assets/images/all/icon_location_yellow.svg';
import locationIcon_orange from '@/assets/images/all/icon_location_orange.svg';
import locationIcon_purple from '@/assets/images/all/icon_location_purple.svg';
import locationIcon_darkred from '@/assets/images/all/icon_location_darkred.svg';
import locationIcon_epa from '@/assets/images/all/icon_location_epa.svg';
import axios from 'axios';
import config from '@/config.js';

var dataArry=[];

var pmtwo_val="",pmtwo_color="",temp_val="",temp_color="",tvoc_val="",tvoc_color="",pmten_val="",pmten_color="";
var co_val="",co_color="",co2_val="",co2_color="",no2_val="",no2_color="",o3_val="",o3_color="";
var humi_val="",humi_color="",nh3_val="",nh3_color="",h2s_val="",h2s_color="";

var newStationWindRoseChart = null;
var cal_newArry=[],cal_newArry2=[];
var markers=[];
var windGloballayerGroup;
export default{
    name: 'Console',
    data: () => ({
        station_id:"TC0008",
        datetime1:"",
        datetime2:"",
        events:{
            evelist:[]
        }
        /*pmtwo:{
            val:"",
            color:"gray",
        }*/

    }),
    methods:{
        drawWindRoseChart(tt_station){

            var myChart = echarts.init(document.getElementById('chart'));

            var legendName = [
             "< 15.5 m/s",
            "15.5-35.5 m/s",
            "35.5-54.5 m/s",
            "54.5-150.5 m/s",
            "150.5-250.5 m/s",
            "> 250 m/s"
            ]
            
            var option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: '#000'
                }
            },
            textStyle:{
                color: '#fff'
            },
            color: ["#0001F7", "#00B8FE", "#00FFFF", "#00FF68", "#BEFE00", "#FFFF00"],
            angleAxis: {
                type: 'category',
                data: ["北", "北东北", "东北", "东东北", "东", "东东南", "东南", "南东南", "南", "南西南", "西南", "西西南", "西", "西西北", "西北", "北西北"],
                boundaryGap: false, //标签和数据点都会在两个刻度之间的带(band)中间
                axisTick: {
                show: false //是否显示坐标轴刻度
                },
                splitLine: {
                show: true,
                lineStyle: {
                    // color:"black"
                },
                },
                axisLabel: {
                show: true,
                interval: 1, //坐标轴刻度标签的显示间隔，在类目轴中有效
                },
            },
            radiusAxis: {
                min: 0,
                max: 50,
                axisLabel: {
                show: false
                },
                axisTick: {
                show: false //是否显示坐标轴刻度
                },
                axisLine: {
                show: false //是否显示坐标轴轴线
                }
            },
            polar: {},
            series: [{
                type: 'bar',
                data: [17, 2, 3, 4,
                2, 3, 4, 6,
                1, 6, 3, 4,
                2, 3, 4, 6,
                 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4,
                ],
                coordinateSystem: 'polar',
                name: legendName[0],
                stack: 'a'
            }, {
                type: 'bar',
                data: [7, 1, 1, 2,
                2, 3, 4, 6,
                1, 2, 3, 2,
                2, 3, 4, 6,
                 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4,
                ],
                coordinateSystem: 'polar',
                name: legendName[1],
                stack: 'a'
            }, {
                type: 'bar',
                data: [10, 12, 13, 4,
                2, 13, 14, 26,
                11, 12, 23, 34,
                12, 33, 34, 32,
                 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4,
                ],
                coordinateSystem: 'polar',
                name: legendName[2],
                stack: 'a'
            }, {
                type: 'bar',
                data: [10, 2, 13, 2,
                2, 3, 4, 6,
                1, 2, 3, 2,
                2, 3, 4, 6,
                 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4,
                ],
                coordinateSystem: 'polar',
                name: legendName[3],
                stack: 'a'
            }, {
                type: 'bar',
                data: [10, 2, 13, 4,
                2, 3, 4, 6,
                1, 2, 3, 4,
                1, 2, 3, 1,
                 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4,
                ],
                coordinateSystem: 'polar',
                name: legendName[4],
                stack: 'a'
            }, {
                type: 'bar',
                data: [10, 2, 13, 2,
                2, 3, 4, 6,
                1, 2, 3, 2,
                1, 2, 3, 1,
                 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4,
                ],
                coordinateSystem: 'polar',
                name: legendName[5],
                stack: 'a'
            }],
            legend: {
                show: true,
                data: legendName,
                textStyle:{
                    color:'#fff'
                },
                width: 500, //根据宽度调整换行
            }
            };

            myChart.setOption(option);
        },

        drawHighChart(stationId){
            $(".highchart-width-wrapper .loading-area").show();
            $(".highchart-width-wrapper .hint-txt").text("資料量大，請耐心等候");
            cal_newArry=[];
            cal_newArry2=[];

            //時間抓 3小時前
            var date1 = new Date();
            //var date2_calc = date1.getTime() - 3*60*60*1000; //3小時前
            var date2_calc = date1.getTime() - 1*60*60*1000; //1小時前
            var date2 = new Date(date2_calc);

            var sTime = date2;
            var eTime = date1;
            var s_time = sTime.getFullYear() + '-' + (sTime.getMonth() + 1) + '-' + sTime.getDate() + ' ' + sTime.getHours() + ':' + sTime.getMinutes() + ':' + sTime.getSeconds();
            var e_time = eTime.getFullYear() + '-' + (eTime.getMonth() + 1) + '-' + eTime.getDate() + ' ' + eTime.getHours() + ':' + eTime.getMinutes() + ':' + eTime.getSeconds();
            var station_str = stationId;

//2022-8-10 08:45
//2022-8-10 11:45

            const apiurl_envSingleRec=config.geturl(config.API.env_single_records,{stime:s_time,etime:e_time,station:station_str});
            //const apiurl_envSingleRec=config.geturl(config.API.env_on_single_records,{stime:"2022-8-10 08:45",etime:"2022-8-10 09:45",station:station_str});
            //const apiurl_envSingleRec=config.geturl(config.API.env_on_single_records,{stime:"2022-6-7 08:45",etime:"2022-6-10 09:45",station:station_str});
            axios.get(apiurl_envSingleRec).then(response => {
                if(response.data != ""){
                    var arry=response.data;

                    var unit = "minute";
                    var oridate="";
                    //先整理日期資料
                        // for (var i = 0; i < arry.length; i++){
                        //     //oriID="";
                        //     var date_spe=arry[i]["time"].split('/');
                        //     var date_spe2=date_spe[2].split(' ');
                        //     var date_spe3=date_spe2[1].split(':');
                        //     if(unit == "date"){
                        //         oridate = date_spe[0] + "/" + date_spe[1] + "/" + date_spe2[0];
                        //     }else if(unit == "hour"){
                        //         oridate = date_spe[0] + "/" + date_spe[1] + "/" + date_spe2[0] + " " + date_spe3[0];
                        //     }else if(unit == "minute"){
                        //         oridate = date_spe[0] + "/" + date_spe[1] + "/" + date_spe2[0] + " " + date_spe3[0] + " " + date_spe3[1];
                        //     }
                            
                        //     cal_newArry.push({
                        //         temp:arry[i]["temp"],
                        //         hum:arry[i]["hum"],
                        //         pm25:arry[i]["pm25"],
                        //         tvoc:arry[i]["tvoc"],
                        //         time:oridate
                        //     });
                        // }

                        // //重組
                        // var temp_val=0,hum_val=0,pm25_val=0,tvoc_val=0;
                        // var jary=0;
                        // //var pu_val=0;
                        // //重組相同日期
                        // for(var a=0;a < cal_newArry.length;a++){
                        //     var next_date= cal_newArry[a]["date"];
                        //     if((a+1) < cal_newArry.length){
                        //         next_date = cal_newArry[a+1]["date"];
                        //     }
                        //     if(cal_newArry[a]["date"] == next_date && (a+1) < cal_newArry.length){
                        //         temp_val += parseFloat(cal_newArry[a]["temp"]);
                        //         hum_val += parseFloat(cal_newArry[a]["hum"]);
                        //         pm25_val += parseFloat(cal_newArry[a]["pm25"]);
                        //         tvoc_val += parseFloat(cal_newArry[a]["tvoc"]);
                        //         jary++;
                        //     }else{
                        //         //自己這筆也要加

                        //         temp_val += parseFloat(cal_newArry[a]["temp"]);
                        //         hum_val += parseFloat(cal_newArry[a]["hum"]);
                        //         pm25_val += parseFloat(cal_newArry[a]["pm25"]);
                        //         tvoc_val += parseFloat(cal_newArry[a]["tvoc"]);
                        //         jary++;
                        //         cal_newArry2.push({
                        //             temp:Math.round(temp_val * 100/jary) / 100,
                        //             hum:Math.round(hum_val * 100/jary) / 100,
                        //             pm25:Math.round(pm25_val * 100/jary) / 100,
                        //             tvoc:Math.round(tvoc_val * 100/jary) / 100,
                        //             time:cal_newArry[a]["date"]
                        //         });
                        //         temp_val=0;
                        //         hum_val=0;
                        //         pm25_val=0;
                        //         tvoc_val=0;
                        //         jary=0;
                        //     }
                        // }


                    var data1=[],data2=[],data3=[],data4=[],data5=[],data6=[],data7=[];
                    $.each(arry,function(index,item){
                        data1.push(parseFloat(item.pm25));
                        data3.push(parseFloat(item.tvoc));
                        data6.push(parseFloat(item.temp));
                        data7.push(parseFloat(item.hum));
                    });

                    Highcharts.chart('hichart-container', {

                        chart: {
                            scrollablePlotArea: {
                                minWidth: 700
                            },
                            zoomType: 'xy',
                            resetZoomButton: {
                                // 按钮定位
                                position:{
                                    align: 'right', // by default
                                    verticalAlign: 'top', // by default
                                    x: 0,
                                    y: -30
                                },
                                // 按钮样式
                                theme: {
                                    fill: 'white',
                                    stroke: 'silver',
                                    r: 0,
                                    states: {
                                        hover: {
                                            fill: '#41739D',
                                            style: {
                                                color: 'white'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        title: {
                            text: '交叉分析'
                        },
                        yAxis: [
                            {
                                title: {
                                    text: null
                                },
                                labels: {
                                    formatter: function () {
                                        var label = this.value;
                                        return label;
                                    },
                                    style: {
                                        color: '#7798bf',
                                        fontSize:18
                                    }
                                }
                            },
                            {
                                title: {
                                    text: null
                                },
                                labels: {
                                    formatter: function () {
                                        var label = this.value;
                                        return label;
                                    },
                                    style: {
                                        color: '#f45b5b',
                                        fontSize:18
                                    }
                                }
                            },
                            {
                                title: {
                                    text: null
                                },
                                labels: {
                                    formatter: function () {
                                        var label = this.value;
                                        return label;
                                    },
                                    style: {
                                        color: '#90ee7e',
                                        fontSize:18
                                    }
                                }
                            },
                            {
                                title: {
                                    text: null
                                },
                                labels: {
                                    formatter: function () {
                                        var label = this.value;
                                        return label;
                                    },
                                    style: {
                                        color: '#2b908f',
                                        fontSize:18
                                    }
                                }
                            }
                        ],

                        xAxis: {
                            /*accessibility: {
                            rangeDescription: 'Range: 2010 to 2017'
                            }*/
                        },

                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            borderWidth: 0
                        },

                        tooltip: {
                            shared: true,
                            crosshairs: true
                        },

                        plotOptions: {
                            series: {
                            cursor: 'pointer',
                            className: 'popup-on-click',
                            marker: {
                                lineWidth: 1
                            }
                            }
                        },

                        series: [{
                                yAxis: 0,
                                name: 'PM2.5(μg/m3)',
                                data:data1
                            }, {
                                yAxis: 1,
                                name: 'TVOC(ppm)',
                                data:data3
                            }, {
                                yAxis: 2,
                                name: '溫度(˚C)',
                                data:data6
                            }, {
                                yAxis: 3,
                                name: '濕度(%)',
                                data:data7
                            }]
                    });
                    $(".highchart-width-wrapper .hint-txt").text("");
                    $(".hichart-container").show();
                }else{
                    $(".highchart-width-wrapper .hint-txt").text("查無資料");
                    $(".hichart-container").hide();
                }

                $(".highchart-width-wrapper .loading-area").hide();
                

            });
           
        },
        fetchRecords(item){
            let device_id = item.device;
            this.drawHighChart(device_id);
            this.markerFunction(device_id);
        },
        markerFunction(id){
            //console.log(markers);
            
            for (let i in markers){
                let markerID = markers[i].options.title;
                if (markerID == id){
                    markers[i].openPopup();
                };
                
            }
        }
        
        /*markerOnClick(currLat,currLng){
            var currData=dataArry.filter(item => parseFloat(item.lat) === currLat && parseFloat(item.lon) === currLng);
            if(currData.length > 0){
                var pm25_val = currData[0].pm25;

                this.pmtwo.val = pm25_val;
                this.pmtwo.color = config.chkPm_val(pm25_val);

            }else{
                //沒資料
                $(window).alert("查無資料");
            }
        }*/
    },
    mounted(){

        var self=this;// assign context to self variable
        $(function() {
            $(".nav_mainTitle").text("戰情室");
        });

        

//地圖

var map;
                var marker;
                var polygon;
                var overLatlng;
                var overRadius=200;
                
                var map = L.map('leafletmap').fitWorld();
                map.locate({setView: true, maxZoom: 16});
                map.on('locationfound', function(e){
                    var radius = e.accuracy;
                    L.marker(e.latlng).addTo(map)
                        .bindPopup("系統預設位置").openPopup();
                    //L.circle(e.latlng, radius).addTo(map);
                    overLatlng = e.latlng;
                    overRadius = radius;
                });
                map.on('locationerror', function(){
                    //不允許就定位在中區
                    var radius = 1952.9697806214715;
                    L.marker([24.1746431,120.6774942]).addTo(map)
                        .bindPopup("系統預設位置").openPopup();
                    //L.circle([24.1746431,120.6774942], radius).addTo(map);
                    overLatlng = [24.1746431,120.6774942];
                    overRadius = radius;

                    map.setView([24.1746431,120.6774942],13);
                });

                //全螢幕
                map.addControl(new L.Control.Fullscreen());
                //ruler
                var options = {
                    position: 'topleft',
                    lengthUnit: {                 // You can use custom length units. Default unit is kilometers.
                        display: 'km',              // This is the display value will be shown on the screen. Example: 'meters'
                        decimal: 2,                 // Distance result will be fixed to this value. 
                        factor: null,               // This value will be used to convert from kilometers. Example: 1000 (from kilometers to meters)  
                        label: '距離:'           
                    },
                    angleUnit: {
                        display: '&deg;',           // This is the display value will be shown on the screen. Example: 'Gradian'
                        decimal: 2,                 // Bearing result will be fixed to this value.
                        factor: null,                // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
                        label: '角度:'
                    }
                };
                L.control.ruler(options).addTo(map);


                var markerlayerGroup = L.layerGroup().addTo(map);
                var windcwblayerGroup = L.layerGroup();
                var epalayerGroup = L.layerGroup();
                windGloballayerGroup = L.layerGroup();
                //圖層
                var baselayers = {
                '街景': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
                '地景圖': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}')
                };
                //複選圖層
                var overlays = {
                    '環保署':epalayerGroup,
                    '氣象站':windcwblayerGroup,
                    '微型感測器':markerlayerGroup,
                    '風場':windGloballayerGroup
                };
                L.control.layers(baselayers, overlays).addTo(map);
                baselayers['街景'].addTo(map);

                epalayerGroup.setZIndex(1);
                windcwblayerGroup.setZIndex(2);
                markerlayerGroup.setZIndex(3);

                var LeafIcon = L.Icon.extend({
                    options: {
                        iconSize: [15, 15],
                    iconAnchor: [0, 0],
                    popupAnchor: [0, 0]
                    }
                });

                var greenIcon = new LeafIcon({iconUrl: locationIcon}),
                    redIcon = new LeafIcon({iconUrl: locationIcon_red}),
                    yellowIcon = new LeafIcon({iconUrl: locationIcon_yellow}),
                    orangeIcon = new LeafIcon({iconUrl: locationIcon_orange}),
                    purpleIcon = new LeafIcon({iconUrl: locationIcon_purple}),
                    darkredIcon = new LeafIcon({iconUrl: locationIcon_darkred}),
                    epaIcon = new LeafIcon({iconUrl: locationIcon_epa});

                L.icon = function (options) {
                    return new L.Icon(options);
                };

            const apiurl=config.geturl(config.API.realtime,{limit:1000});
            axios.get(apiurl).then(response => {

                

                //全部出現
            
                dataArry=response.data;

                //一開始就出現所有點
                var i = 0,currTime="";

                $.each(dataArry, function() {
                    var iconType=greenIcon;
                        if(dataArry[i].pm25 !== "" && dataArry[i].pm25 !== null && typeof(dataArry[i].pm25) !== "undefined"){
                            if(parseFloat(dataArry[i].pm25) <= 15.4){
                                iconType=greenIcon;
                            }else if(parseFloat(dataArry[i].pm25) > 15.4 && parseFloat(dataArry[i].pm25) <= 35.4){
                                iconType=yellowIcon;
                            }else if(parseFloat(dataArry[i].pm25) > 35.4 && parseFloat(dataArry[i].pm25) <= 54.4){
                                iconType=orangeIcon;
                            }else if(parseFloat(dataArry[i].pm25) > 54.4 && parseFloat(dataArry[i].pm25) <= 150.4){
                                iconType=redIcon;
                            }else if(parseFloat(dataArry[i].pm25) > 150.4 && parseFloat(dataArry[i].pm25) <= 250.4){
                                iconType=purpleIcon;
                            }else{
                                iconType=darkredIcon;
                            }
                        }

                        if(dataArry[i].lat !== "" && dataArry[i].lat !== null && typeof(dataArry[i].lat) !== "undefined" && dataArry[i].lon !== "" && dataArry[i].lon !== null && typeof(dataArry[i].lon) !== "undefined"){
                            marker = L.marker([dataArry[i].lat,dataArry[i].lon], {
                                title:dataArry[i].device,
                                icon: iconType
                                }).addTo(map);
                                markers.push(marker);
                        }

                        var mp_co=(dataArry[i].co !== null)?dataArry[i].co:"0";
                        var mp_co2=(dataArry[i].co2 !== null)?dataArry[i].co2:"0";
                        var mp_no2 =(dataArry[i].no2 !== null)?dataArry[i].no2:"0";
                        var mp_o3 =(dataArry[i].o3 !== null)?dataArry[i].o3:"0";
                        var mp_hum=(dataArry[i].hum !== null)?dataArry[i].hum:"0";
                        var mp_nh3 =(dataArry[i].nh3 !== null)?dataArry[i].nh3:"0";
                        var mp_h2s =(dataArry[i].h2s !== null)?dataArry[i].h2s:"0";
    
                        marker.bindPopup("<div class='lc-title'>感測器：" + dataArry[i].device + "</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>PM<sub>2.5</sub>：" + dataArry[i].pm25 + "<br/>溫度：" + dataArry[i].temp + "<br/>TVOC：" + dataArry[i].tvoc + "<br/>PM10：" + dataArry[i].pm10 + "<br>CO：" + mp_co + "<br>CO2：" + mp_co2 + "<br>NO2：" + mp_no2 + "</div><div class='col-md-6 col-12 item'>" + "O3：" + mp_o3 + "<br>濕度：" + mp_hum + "<br>NH3：" + mp_nh3 + "<br>H2S：" + mp_h2s +"<br>地區：" + dataArry[i].area+"<br>類型：" + dataArry[i].areatype+"<br>鎮/區：" + dataArry[i].town + "</div><div class='col-12 item'><div class='hint'>更新時間：" + dataArry[i].time + "</div></div></div>")

                        marker.on('click', function (e) {
                            /*$(".leaflet-marker-icon").removeClass("selectedMarker");
                            $(e.target._icon).addClass('selectedMarker');*/
                            var currLat = e.latlng.lat,currLng = e.latlng.lng;
                            var currData=dataArry.filter(item => parseFloat(item.lat) === currLat && parseFloat(item.lon) === currLng);
                                if(currData.length > 0){

                                    self.station_id = currData[0].device;

                                    //重畫交叉分析
                                    self.drawHighChart(self.station_id);
                                    //重劃風向
                                    //self.drawWindRoseChart(self.station_id);

                                    showStationSingleData(currData[0]);
                                    


                                }else{
                                    //沒資料
                                    
                                }


                        }).addTo(map);

                        markerlayerGroup.addLayer(marker);


                    i++;

                });


                //初始值(TC0008 > )
                /*
                lat: "24.225408",
                lon: "120.569226"
                */
                var initStation = this.station_id;
                var initAnaArry=dataArry.filter(item => item.device === initStation);
                if(initAnaArry.length > 0){
                    //重畫交叉分析
                    this.drawHighChart(this.station_id);
                    var initCurrArry = initAnaArry[0];
                    showStationSingleData(initCurrArry);
                }


                //氣象站
                const apiurl_cwbwind=config.geturl(config.API.cwb_wind,{});
                axios.get(apiurl_cwbwind).then(response => {
                    if(response.data !== "" && response.data.length > 0){

                        var cwbWindData = response.data;
                        $.each(cwbWindData,function(index,cw_item){
                            //風向
                            var wd_d = cw_item.wd;
                            var lat_pos=cw_item.lat;
                            var lon_pos=cw_item.lon;
                            var wdCul = wd_cul(wd_d);
                            var lat_cul=Math.cos(wd_d),lon_cul=Math.sin(wd_d);
                            if(wdCul=="1"){
                                lat_cul = Math.abs(lat_cul);
                                lon_cul = Math.abs(lon_cul);
                            }else if(wdCul=="2"){
                                lat_cul = Math.abs(lat_cul) * -1;
                                lon_cul = Math.abs(lon_cul);
                            }else if(wdCul=="3"){
                                lat_cul=Math.abs(lat_cul) * -1;
                                lon_cul=Math.abs(lon_cul) * -1;
                            }else if(wdCul=="4"){
                                lat_cul=Math.abs(lat_cul);
                                lon_cul=Math.abs(lon_cul) * -1;
                            }
                            
                            var polyline = L.polyline([[lat_pos+(lat_cul/1000),lon_pos+(lon_cul/1000)],[lat_pos,lon_pos]], {});
                            var decorator = L.polylineDecorator(polyline, {
                                    patterns: [
                                        {offset: '100%', repeat: 0, symbol: L.Symbol.arrowHead({pixelSize: 15, polygon: false, pathOptions: {stroke: true}})}
                                    ]
                                });


                            decorator.bindPopup("<div class='lc-title'>" + cw_item.name + "測站</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>溫度：" + cw_item.temp + "<br/>濕度：" + cw_item.hum + "</div><div class='col-md-6 col-12 item'>" + "風向：" + cw_item.wd + "<br>風速：" + cw_item.ws + "</div><div class='col-12 item pt-0'>大氣壓力：" + cw_item.press + "<div class='hint'>更新時間：" + cw_item.time + "</div></div></div>")
                            polyline.bindPopup("<div class='lc-title'>" + cw_item.name + "測站</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>溫度：" + cw_item.temp + "<br/>濕度：" + cw_item.hum + "</div><div class='col-md-6 col-12 item'>" + "風向：" + cw_item.wd + "<br>風速：" + cw_item.ws + "</div><div class='col-12 item pt-0'>大氣壓力：" + cw_item.press + "<div class='hint'>更新時間：" + cw_item.time + "</div></div></div>")
                                windcwblayerGroup.addLayer(polyline);
                            windcwblayerGroup.addLayer(decorator);

                        });

                    }
                })


                //環保署
                const apiurl_epa=config.geturl(config.API.env_epa,{limit:1000});
                axios.get(apiurl_epa).then(response => {
                    if(response.data !== "" && response.data.length > 0){
                        var epaData = response.data;
                        var icon_epa=epaIcon;
                        $.each(epaData,function(index,epitem){
                            var epa_marker;
                            var lat_epa=epitem.lat,lon_epa=epitem.lon;
                            if(lat_epa !== "" && lat_epa !== null && typeof(lat_epa) !== "undefined" && lon_epa !== "" && lon_epa !== null && typeof(lon_epa) !== "undefined"){
                                epa_marker = L.marker([lat_epa,lon_epa], {icon: icon_epa});
                                epa_marker.bindPopup("<div class='lc-title'>環保署" + epitem.name + "測站</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>AQI數值：" + epitem.aqi + "<br/>CO：" + epitem.co+ "<br/>CO平均數值：" + epitem.co_8hr + "<br>NO：" + epitem.no + "<br>NO2：" + epitem.no2 + "<br>NOx：" + epitem.nox + "<br>O3：" + epitem.o3 + "<br>O3平均數值：" + epitem.o3_8hr + "</div><div class='col-md-6 col-12 item'>" + "PM2.5：" + epitem.pm25 + "<br>PM2.5平均數值：" + epitem.pm25_avg + "<br>PM10：" + epitem.pm10 + "<br>PM10平均數值：" + epitem.pm10_avg + "<br>SO2：" + epitem.so2 + "<br>SO2：平均數值：" + epitem.so2_avg + "<br>風向：" + epitem.wd + "<br>風速：" + epitem.ws  + "</div><div class='col-12 item pt-0'>PM2.5：" + epitem.pm25 + "<div class='hint'>更新時間：" + epitem.time + "</div></div></div>")
                            }
                            epalayerGroup.addLayer(epa_marker);
                        });
                    }
                });

                //風場
                /*const apiurl_envwind=config.geturl(config.API.env_wind,{});

                axios.get("https://lab.ep.ezcs.com.tw/cmd/wind.json").then(response_wind => {
                    console.log(apiurl_envwind);

                    if(response_wind.data !== "" && response_wind.data.length > 0){


                        //console.log(response_wind.data);

                        var velocityLayer = L.velocityLayer({
                            displayValues: false,
                            displayOptions: {
                            velocityType: "Global Wind",
                            position: "bottomleft",
                            emptyString: "No wind data"
                            },
                            data: response_wind.data,
                            velocityScale:0.015,
                            maxVelocity: 10,
                            colorScale: ['#bd0026', '#f03b20', '#fd8d3c', '#fecc5c', '#ffffb2']
                        });
                        windGloballayerGroup.addLayer(velocityLayer);
                    }
                });*/
                

            })


            //風向圖
            this.drawWindRoseChart(this.station_id);

            //交叉分析
            this.drawHighChart(this.station_id);

        // 示警表格
        const apiurl_trace=config.geturl(config.API.event_com_all,{});
            axios.get(apiurl_trace).then(response => {
                if(response.data !== "" && response.data.length > 0){
                    this.events.evelist = response.data;
                }
            });


                //風場
                const apiurl_envwind=config.geturl(config.API.env_wind,{});

                axios.get("https://lab.ep.ezcs.com.tw/cmd/wind.json").then(response_wind => {
                    console.log(response_wind.data);

                    var velocityLayer = L.velocityLayer({
                            displayValues: false,
                            displayOptions: {
                            velocityType: "Global Wind",
                            position: "bottomleft",
                            emptyString: "No wind data"
                            },
                            data: response_wind.data,
                            velocityScale:0.015,
                            maxVelocity: 10,
                            colorScale: ['#bd0026', '#f03b20', '#fd8d3c', '#fecc5c', '#ffffb2']
                        });
                        windGloballayerGroup.addLayer(velocityLayer);

                });


    },
    
}

function showStationSingleData(currAry){

    var pm25_val = currAry.pm25;
    var temperature_val = (currAry.temp !== null && currAry.temp !=="")?currAry.temp:"NaN";
    var tvocc_val = (currAry.tvoc !== null && currAry.tvoc !=="")?currAry.tvoc:"NaN";
    var pm10_val = (currAry.pm10 !== null && currAry.pm10 !=="")?currAry.pm10:"NaN";
    var co_tp_val = (currAry.co !== null && currAry.co !=="")?currAry.co:"NaN";
    var co2_tp_val = (currAry.co2 !== null && currAry.co2 !=="")?currAry.co2:"NaN";
    var hum_tp_val = (currAry.hum !== null && currAry.hum !=="")?currAry.hum:"NaN";
    var nh3_tp_val = (currAry.nh3 !== null && currAry.nh3 !=="")?currAry.nh3:"NaN";
    var h2s_tp_val = (currAry.h2s !== null && currAry.h2s !=="")?currAry.h2s:"NaN";
    var o3_tp_val = (currAry.o3 !== null && currAry.o3 !=="")?currAry.o3:"NaN";
    var no2_tp_val = (currAry.no2 !== null && currAry.no2 !=="")?currAry.no2:"NaN";

    
    if(pm25_val !== "NaN"){
        pmtwo_val = pm25_val;
        pmtwo_color = chkPm_val(pm25_val);
        $(".pmtwo_item .dot").attr("class","dot " + pmtwo_color);
        $(".pmtwo_item .cont").attr("class","cont " + pmtwo_color);
        $(".pmtwo_item .cont").text(pmtwo_val);
    }
    
    if(pm10_val !== "NaN"){
        pmten_val = pm10_val;
        pmten_color = chkPm10_val(pm10_val);
        $(".pmten_item .dot").attr("class","dot " + pmten_color);
        $(".pmten_item .cont").attr("class","cont " + pmten_color);
        $(".pmten_item .cont").text(pmten_val);
    }
    
    if(temperature_val !== "NaN"){
        temp_val = temperature_val;
        temp_color = chkTemp_val(temp_val);
        $(".temp_item .dot").attr("class","dot " + temp_color);
        $(".temp_item .cont").attr("class","cont " + temp_color);
        $(".temp_item .cont").text(temp_val);
    }
    
    if(tvocc_val !== "NaN"){
        tvoc_val = tvocc_val;
        tvoc_color = chkTvoc_val(tvoc_val);
        $(".tvoc_item .dot").attr("class","dot " + tvoc_color);
        $(".tvoc_item .cont").attr("class","cont " + tvoc_color);
        $(".tvoc_item .cont").text(tvoc_val);
    }

    if(co_tp_val !== "NaN"){
        co_val = co_tp_val;
        co_color = chkCO_val(co_val);
        $(".co_item .dot").attr("class","dot " + co_color);
        $(".co_item .cont").attr("class","cont " + co_color);
        $(".co_item .cont").text(co_val);
    }

    if(co2_tp_val !== "NaN"){
        co2_val = co2_tp_val;
        co2_color = chkTvoc_val(co2_val);
        $(".co2_item .dot").attr("class","dot " + co2_color);
        $(".co2_item .cont").attr("class","cont " + co2_color);
        $(".co2_item .cont").text(co2_val);
    }

    if(hum_tp_val !== "NaN"){
        humi_val = hum_tp_val;
        humi_color = chkTvoc_val(humi_val);
        $(".humi_item .dot").attr("class","dot " + humi_color);
        $(".humi_item .cont").attr("class","cont " + humi_color);
        $(".humi_item .cont").text(humi_val);
    }

    if(nh3_tp_val !== "NaN"){
        nh3_val = nh3_tp_val;
        nh3_color = chkTvoc_val(nh3_val);
        $(".nh3_item .dot").attr("class","dot " + nh3_color);
        $(".nh3_item .cont").attr("class","cont " + nh3_color);
        $(".nh3_item .cont").text(nh3_val);
    }

    if(h2s_tp_val !== "NaN"){
        h2s_val = h2s_tp_val;
        h2s_color = chkTvoc_val(h2s_val);
        $(".h2s_item .dot").attr("class","dot " + h2s_color);
        $(".h2s_item .cont").attr("class","cont " + h2s_color);
        $(".h2s_item .cont").text(h2s_val);
    }

    if(o3_tp_val !== "NaN"){
        o3_val = o3_tp_val;
        o3_color = chkO3_val(o3_val);
        $(".o3_item .dot").attr("class","dot " + o3_color);
        $(".o3_item .cont").attr("class","cont " + o3_color);
        $(".o3_item .cont").text(o3_val);
    }

    if(no2_tp_val !== "NaN"){
        no2_val = no2_tp_val;
        no2_color = chkNO2_val(no2_val);
        $(".no2_item .dot").attr("class","dot " + no2_color);
        $(".no2_item .cont").attr("class","cont " + no2_color);
        $(".no2_item .cont").text(no2_val);
    }
}


function markerOnClick(currLat,currLng){
    //var currLat = e.latlng.lat,currLng = e.latlng.lng;
    var currData=dataArry.filter(item => parseFloat(item.lat) === currLat && parseFloat(item.lon) === currLng);
    if(currData.length > 0){
        var pm25_val = currData[0].pm25;

        pmtwo_val = pm25_val;
        pmtwo_color = chkPm_val(pm25_val);

        $(".pmtwo_item .dot").addClass(pmtwo_color);
        $(".pmtwo_item .cont").addClass(pmtwo_color);
        $(".pmtwo_item .cont").val(pmtwo_val);



    }else{
        //沒資料
        
    }
}

</script>